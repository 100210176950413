import React from "react"
import {Helmet} from 'react-helmet'
import SEO from '../components/seo'
import Link from "gatsby-link"
import { graphql } from 'gatsby'
import Image from 'gatsby-image'
import MediaQuery from 'react-responsive'

import Layout from "../layouts/layout-scroll"

const Head = (props) => (
  <>
    <h1>Projects</h1>
    <div style={{
      display: 'flex',
      justifyContent: 'center',
    }}
    className="project-head"
    >
      <Image
        fluid={props.image}
        alt="desk"
        style={{
          width: '100%',
          maxWidth: '720px'
        }}
      />
    </div>
    <i style={{
      fontSize: '2.5em',
      position: 'absolute',
      bottom: '0.4rem',
      textAlign: 'center',
      width: '100%',
    }} className="fas fa-angle-down"></i>
    </>
)

const Project = (props) => (
  <div style={{
    backgroundColor: `${props.node.frontmatter.background}cc`,
    position: 'relative',
    minHeight: '5rem',
    minWidth: '15rem'
  }}
    className="grid-item"
    key={`${props.node.fields.slug}`+'ghj'}
    >
    <a href={props.node.frontmatter.link} target="_blank" style={{
      textAlign: 'center',
      width: '100%'
    }}>
    <p style={{
      marginBottom: 0,
      fontFamily: 'sandbrush-pro',
      fontSize: '2.5em'
    }}>
      {props.node.frontmatter.title}
    </p>
    <div style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      margin: 0
      }}
      className="grid-item-caption-container"
      >
      <p style={{
        margin: 0
      }}>
        {props.node.frontmatter.web &&
          <i className="fa fa-desktop" aria-hidden="true"></i>
        }
        &nbsp;
        {props.node.frontmatter.mobile &&
          <i className="fa fa-mobile" aria-hidden="true"></i>
        }
        &nbsp;
        {props.node.frontmatter.mobile &&
          <i className="fa fa-tablet" aria-hidden="true"></i>
        }
      </p>
    </div>
    </a>
  </div>
)

export default ({data}) => {
    return (
      <Layout>
        <Helmet htmlAttributes={{ class:'pro projects' }} />
        <SEO title="Projects"
          description={data.site.siteMetadata.descriptions.projects} />
        <>
          <MediaQuery query="(min-device-width: 1224px)">
            <div style={{
              height: 'calc(100vh - 5rem)',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              paddingBottom: '5rem',
              position: 'relative',
            }}
            >
              <Head image={data.desk.childImageSharp.fluid} />
            </div>
          </MediaQuery>
          <MediaQuery query="(max-device-width: 1224px)">
            <div style={{
              height: '25rem',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-around',
              position: 'relative',
            }}
            >
              <Head image={data.desk.childImageSharp.fluid} />
            </div>
          </MediaQuery>
          <div className="container-fluid" style={{ marginTop: '1rem'}}>
            <div className="row">
              <div className="col-xs-10 col-xs-offset-1 col-sm-8 col-sm-offset-2">
                  <ul className="timeline timeline-centered">
                    <li className="timeline-item period">
                        <div className="timeline-info"/>
                        <div className="timeline-marker"/>
                        <div className="timeline-content">
                            <h2 className="timeline-title">Side projects</h2>
                        </div>
                    </li>
                    <li className="timeline-item period" >
                      <div className="timeline-info"/>
                      <div className="timeline-marker"/>
                      <div className="timeline-content">
                        <div className="grid-container" >
                          {data.sideProjects.edges.map(({node}) =>
                            <Project node={node} />
                          )}
                        </div>
                      </div>
                    </li>
                    <li className="timeline-item period">
                        <div className="timeline-info"/>
                        <div className="timeline-marker"/>
                        <div className="timeline-content">
                            <h2 className="timeline-title">Work projects</h2>
                        </div>
                    </li>
                    <li className="timeline-item period">
                      <div className="timeline-info"/>
                      <div className="timeline-marker"/>
                      <div className="timeline-content">
                        <div className="grid-container" >
                          {data.allProjects.edges.map(({node}) =>
                            <Project node={node} />
                          )}
                        </div>
                      </div>
                    </li>

                  </ul>
              </div>
              <p style={{ textAlign: 'center' }}>
                Feel free to have a look at my - almost full - <Link to='/resume' >resume</Link>
              </p>
            </div>
          </div>
        </>
      </Layout>
    )
}

export const query = graphql `
  query ProjectsQuery {
    site {
      siteMetadata {
        title
        descriptions {
          projects
        }
      }
    }
    allProjects: allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }, filter: { frontmatter: { type: { eq: "project"}, tags: { ne: "side-project"}, visible: { eq: true}}}) {
      totalCount
      edges {
        node {
          frontmatter {
            type
            link
            title
            date(formatString: "YYYY")
            background
            mobile
            web
          }
          fields {
            slug
          }
          excerpt
          html
        }
      }
    }
    sideProjects: allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }, filter: { frontmatter: { type: { eq: "project"}, tags: { eq: "side-project"}, visible: { eq: true}}}) {
      totalCount
      edges {
        node {
          frontmatter {
            type
            link
            title
            date(formatString: "YYYY")
            background
            mobile
            web
          }
          fields {
            slug
          }
          excerpt
          html
        }
      }
    }
    desk: file(absolutePath: { regex: "/desk-5.png/" }) {
      childImageSharp {
        fixed(width: 1440, height: 730) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
        fluid(maxWidth: 960) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
  }
`
